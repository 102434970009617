import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHumainsEtAnimaux } from '../components/PageHumainsEtAnimaux/PageHumainsEtAnimaux';

const HumainsEtAnimaux = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
        <SEO title="Humains et Animaux" />
        <PageHumainsEtAnimaux />
    </Layout>
  )
}

export default HumainsEtAnimaux

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`