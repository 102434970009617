import styled from 'styled-components'

const StyledPageHumainsEtAnimaux = styled.section`
    width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

        .container-page {
            max-width: 1384px;
            padding: 0 24px;
        }

        h3 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            padding: 24px 0 48px 0;
        }
        h4 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 21px;
            line-height: 25px;
            color: #000000;
            padding: 24px 0;
        }
        small {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
            text-transform: capitalize;
            padding: 0 4px;
        }
        .container-txt1 {
            width: 100%;
            height: auto;
            margin-bottom: 24px;
            position: relative;
        }

        #logo {
            float: right;
            align-self: flex-end;
            height: 100px;
        }

        ol {
            list-style: decimal;
            list-style-position: inside;
        }

        em {
            display: initial;
            font-size: 14px;
        }

        p, li {
            padding: 6px 0;
        }
        li::marker {
            padding-left: 24px;
        }

        .container-img-txt2 {
            width: 100%;
            height: auto;
            margin-bottom: 48px;
            position: relative;
            align-items: center;
            display: flex;
        }
        #img {
            width: 35%;
            z-index: -1;
        }
        .container-txt2 {
            width: 65%;
            background: #F4E1F4;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
            padding: 24px;
        }
        .container-txt3 {
            width: 65%;
            background: #F4E1F4;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
            padding: 24px;
            text-align: left;
        }

        .separator {
            width: 100vw;
            height: auto;
            background: linear-gradient(180deg, #8F6A8F 0%, #4E1D4E 100%);
            display: flex;
            padding: 24px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
        }
        .separator p {
            font-family: Lato;
            font-style: italic;
            font-weight: bold;
            font-size: 26px;
            line-height: 32px;
            text-align: center;
            color: #FFFFFF;
            width: 80%;
            margin-bottom: 12px;
        }

        .separator .btnToHome {
            background: linear-gradient(180deg, #F4E1F4 0%, #B08DB0 100%);
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #FFFDF9;
            padding: 8px 24px;
            cursor: pointer;
            transition: 400ms;
            text-decoration: none;
        }

        .separator .btnToHome:hover {
            transform: scale(1.06);
            transition: 400ms;
        }

        @media screen and (max-width: 700px) {
            h3 {
                font-size: 22px !important;
            }
            small {
                font-size: 19px !important;
            }
            .container-img-txt2 {
                flex-direction: column-reverse;
            }
            #img {
                width: 95%;
            }
            .container-txt2 {
                width: 100%;
            }
            .container-txt3 {
                width: 100%;
            }
            .separator p {
                width: 95%;
                font-size: 21px;
                line-height: 26px;
            }
        }
`

export default StyledPageHumainsEtAnimaux