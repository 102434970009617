import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"

import { GlobalStateContext } from '../../utils/GlobalContextProvider';
import StyledPageHumainsEtAnimaux from './StyledPageHumainsEtAnimaux'
import logoVitaFons from '../../../static/logoVitaFons.jpg'
import woman from '../../../static/woman.jpg'
import men from '../../../static/men.jpg'

export const PageHumainsEtAnimaux = () => {
    const { pageHumainsEtAnimaux } = useStaticQuery(
        graphql`
            query {
                pageHumainsEtAnimaux:datoCmsPageHumainsEtAnimaux {
                    encart1Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart2Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart3Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart4Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                  }
            }
          `)
    const { titlePage } = useContext(GlobalStateContext)
    return (
        <StyledPageHumainsEtAnimaux>
            <div className='container-page'>
                <div className='wrapper-titleh3'>
                    <h3>{titlePage} / <small>Humains et Animaux</small></h3>
                </div>
                <div className='container-img-txt'>
                    <img id='logo' alt='logo' src={logoVitaFons} />
                    <div className='container-txt1' dangerouslySetInnerHTML={{ __html: pageHumainsEtAnimaux.encart1Node.childMarkdownRemark.html}} />
                </div>
                <div className='container-img-txt2'>
                    <div className='container-txt2' dangerouslySetInnerHTML={{ __html: pageHumainsEtAnimaux.encart2Node.childMarkdownRemark.html}} />
                    <img id='img' alt='woman' src={woman} />
                </div>
                <div className='container-img-txt2'>
                    <img id='img' alt='men' src={men} />
                    <div className='container-txt3' dangerouslySetInnerHTML={{ __html: pageHumainsEtAnimaux.encart3Node.childMarkdownRemark.html}} />
                </div>
            </div>
            <div className='separator'>
                <p>
                    Si l'on cherche à acquérir un autre mode d'être,
                    il est essentiel de prendre et d'appliquer les produits VITA FONS II
                    régulièrement et systématiquement.
                </p>
                <Link to='/' className='btnToHome'>
                    Voir les Produits
                </Link>
            </div>
            <div className='container-page'>
                <div className='container-txt1' dangerouslySetInnerHTML={{ __html: pageHumainsEtAnimaux.encart4Node.childMarkdownRemark.html}} />
            </div>
        </StyledPageHumainsEtAnimaux>
    )
}
